<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <button
      toggle="visible"
      cHeaderToggler
      [cSidebarToggle]="sidebarId"
      class="ps-1"
    >
      <svg cIcon
        name="cilMenu"
        size="lg"
      ></svg>
    </button>
    <c-header-nav class="d-none d-lg-flex me-auto">
    </c-header-nav>

    <c-header-nav class="ms-3">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>

    <c-header-nav class="d-none d-lg-flex">
      <c-nav-item>
        <a (click)="onLogout()" cNavLink>
          Logout
        </a>
      </c-nav-item>
    </c-header-nav>

  </c-container>
  <!-- <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
  </c-container> -->
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button
      cButton
      color=""
      [caret]="false"
      cDropdownToggle
      class="py-0"
    >
      <c-avatar
        cTextColor="primary"
        shape="rounded-1"
        size="md"
        src="./assets/images/blank-profile.png"
        status="success"
      >
      </c-avatar>
    </button>
    <!-- <ul cDropdownMenu class="pt-0 pr-5 w-auto">
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilBell"></svg>
          Updates
          <c-badge class="ms-2 float-end" color="info">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilUser"></svg>
          Profile
        </a>
      </li>
      <li>
        <a (click)="onLogout()" cDropdownItem>
          <svg cIcon class="me-2" name="cilLockLocked"></svg>
         Log Out
        </a>
      </li>
      <li></li>
    </ul> -->
  </c-dropdown>
</ng-template>

