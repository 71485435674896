import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminAuthService } from '../../services/auth.services';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  error: any = '';
  success: any = '';
  submitting: boolean = false;
  currentToken: string = '';

  constructor(private spinner: NgxSpinnerService,private toastr: ToastrService,private fb: FormBuilder, private router: Router, private authService: AdminAuthService) { }

  ngOnInit(): void {
    console.log(this.router.url);
    let url = this.router.url
    if (url.includes('/resetpassword/')) {
      this.currentToken = (url.split('/resetpassword/')[1]).split('&')[0]
      console.log(this.currentToken);
    }
    this.initForm();
  }

  initForm() {
    this.resetForm = this.fb.group({
      newPassword: ["", Validators.required],
      confirmPassword: ["", Validators.required],
    });
  }

  submitForm() {
    this.submitting = true
    if (this.resetForm.invalid) {
      return;
    }
    this.spinner.show()
    let data = {
      password: this.resetForm.value.newPassword,
      confirm_password: this.resetForm.value.confirmPassword,
      token: this.currentToken
    }
    this.authService.resetPassword(data).subscribe((res: any) => {
      this.spinner.hide()
      if (res.success) {
        this.toastr.success("Paasword updated successfully")
        this.router.navigate(['/login'])
      }
      else{
        this.toastr.error("Something went wrong")
      }
    },error => {
      this.spinner.hide()
      this.toastr.error("Invalid Token")
    })

  }

}
