import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { AdminAuthService } from '../../services/auth.services';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgetForm: FormGroup;
  submitting: boolean = false;

  constructor(private spinner: NgxSpinnerService, private toastr: ToastrService, private fb: FormBuilder, private router: Router, private authService: AdminAuthService) { }

  ngOnInit(): void {
    this.initForm()
  }
  initForm() {
    this.forgetForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]]
    });
  }

  submitForm() {
    this.submitting = true
    if (this.forgetForm.invalid) {
      return;
    }
    this.spinner.show();
    this.authService.forgotPassword(this.forgetForm.value).subscribe((data: any) => {
      this.spinner.hide()
      if (data.status) {
        this.toastr.success(data.message)
      }
      else { this.toastr.error(data.message) }
    }, error => {
      this.spinner.hide()
      this.toastr.error(error.error.message)
    })


  }

}
