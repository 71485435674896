<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
 <c-container>
   <c-row class="justify-content-center">
     <c-col lg="7" md="9" xl="6">
       <c-card class="mx-4">
         <c-card-body class="p-4">
           <form cForm [formGroup]="resetForm">
             <h1>Reset Your Password</h1>
             <!-- <p class="text-medium-emphasis">We will send new code to your recovery
              email to reset your password.</p> -->
              <c-input-group class="mb-4">
               <span cInputGroupText>
                <svg cIcon name="cilLockLocked"></svg>
               </span>
               <input formControlName="newPassword" cFormControl placeholder="New Password" type="password"/>
             </c-input-group>
             <c-input-group *ngIf="submitting">
              <span *ngIf="resetForm.controls['newPassword'].errors?.['required']" class="danger">*Required</span>
            </c-input-group>
             <c-input-group class="mb-4">
              <span cInputGroupText>
               <svg cIcon name="cilLockLocked"></svg>
              </span>
              <input formControlName="confirmPassword" cFormControl placeholder="Confirm Password" type="password"/>
            </c-input-group>
            <c-input-group *ngIf="submitting">
             <span *ngIf="resetForm.controls['confirmPassword'].errors?.['required']" class="danger">*Required</span>
           </c-input-group>
             <div class="d-grid">
               <button cButton color="primary" (click)="submitForm()">Send</button>
             </div>
             <!-- <div class="text-center">
              <p class="text-medium-emphasis"><a [routerLink]="['/login']">Back to login</a></p>
             </div> -->
           </form>
         </c-card-body>
       </c-card>
     </c-col>
   </c-row>
 </c-container>
</div>
