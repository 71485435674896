import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("kliqueToken") ? 'Bearer ' + localStorage.getItem("kliqueToken"): ''

    if (token) {
      req = req.clone({ headers: req.headers.set("Authorization", token) });
    }
    req = req.clone({ headers: req.headers.set("Accept", "application/json") });
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      })
    );
  }
  
}
