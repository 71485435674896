import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { Router } from '@angular/router';
import { AdminAuthService } from '../../services/auth.services';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  signinForm: FormGroup;
  error: any = '';
  success: any = '';
  submitting: boolean = false;

  constructor(private spinner: NgxSpinnerService,private toastr: ToastrService,private fb: FormBuilder, private router: Router, private authService: AdminAuthService) { }

  ngOnInit() {
    console.log(this.router.url);
    if(this.router.url.includes('email-verified')){
      this.router.navigate(['/email-verified'])
      return;
    }
   
    if (localStorage.getItem('kliqueToken') && localStorage.getItem('kliqueUser')) {
      this.router.navigate(['/home/dashboard'])
    }
    this.initForm();
  
  }

  initForm() {
    this.signinForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }

  
  signIn() {
    this.submitting = true
    if(this.signinForm.invalid){
      return;
      
    }
    this.spinner.show()
    this.authService.signIn(this.signinForm.value).subscribe((data: any) => {
      this.spinner.hide()
      if(data.user){
        this.toastr.success('Login successful')
        localStorage.setItem('kliqueToken', data['token']);
        localStorage.setItem('kliqueUser', JSON.stringify(data['user']));
        this.router.navigate(['/home/dashboard'])
      }
      else{ this.toastr.error('Something went wrong')}
    },error=>{
      this.spinner.hide()
      this.toastr.error(error.error.msg)
    })
  }
}
