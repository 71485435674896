import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from "../../environments/environment";
import { AdminAuthService } from '../authentication/services/auth.services';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {
  tokenFromUrl: string = ''
  baserUrl = environment.baseServerEmailVerified;
  emailVerified: boolean = false;

  constructor(private authService: AdminAuthService, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.tokenFromUrl = params['token']
      this.hitVerfication()
    });
  }

  hitVerfication() {
    this.authService.adminemailverification(this.tokenFromUrl).subscribe((_res: any) => {
      console.log(_res);
      if (_res['success']) {
        this.toastr.success(_res.msg)
        this.emailVerified = true
      }
      else {
        this.toastr.error(_res.msg)
      }
    })
  }
}
