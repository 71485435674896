import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  public authToken: any;
  private isAuthenticated = true; // Set this value dynamically
  
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('kliqueToken') && localStorage.getItem('kliqueUser')) {
      return true
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}