import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
// import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from 'rxjs';

const baserUrl = environment.apiBaseUrl;

@Injectable({ providedIn: "root" })
export class AdminAuthService {

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  signIn(data: any) {
    return this.http.post(`${baserUrl}/users/authorize`, data);
  }

  forgotPassword(data: any) {
    return this.http.post(`${baserUrl}/users/forgetpassword`, data);
  }

  resetPassword(data: any) {
    return this.http.post(`${baserUrl}/users/resetPassword`, data);
  }

  adminemailverification(data: any) {
    return this.http.get(`${baserUrl}/users/verifyaccount/${data}`);
  }


  public logout(): void {
    localStorage.clear()
    this.router.navigate(['/login'])
  }

}
