
// const server = "http://112.196.1.221:9056/api";
// const server = "http://112.196.1.221:9056/api";
// const server = "http://18.156.183.20:9056/api";
const server = "https://backend.klique.online/api";
const host = "http://localhost:4200";
export const environment = {
  production: false,
  apiBaseUrl: server,
  baseServerEmailVerified: 'https://backend.klique.online/',
  imageBaseUrl: "https://d1ea3ctlkg7cqo.cloudfront.net/"   
};


