<div class="preauth-bg min-vh-100 d-flex flex-row align-items-center" style="background-image: url(assets/images/preauthbg.png);">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
  <c-container>
    <c-row class="justify-content-center">
      <c-col md="8">
        <c-card-group>
          <c-card [ngStyle]="{'width.%': 44}" class="text-white theme-bg py-5">
            <c-card-body class="text-center d-flex justify-content-center align-items-center">
              <div>
                <img src="/assets/images/logo-transparent.png" class="loginlog"> 
                <h2 class="page-title">KLIQUE</h2>
              
              </div>
            </c-card-body>
          </c-card>
          <c-card class="p-4">
            <c-card-body>
              <form cForm [formGroup]="signinForm">
                <h1 class="login-title text-center">Login</h1>
                <c-input-group class="mb-3">
                  <span cInputGroupText>
                    <svg cIcon name="cilUser"></svg>
                  </span>
                  <input class="form-control mb-2" cFormControl formControlName="email" name="email" placeholder="Email"
                    type="email">
                    <c-input-group *ngIf="submitting">
                      <span *ngIf="signinForm.controls['email'].errors?.['required']" class="danger error">*Email is
                        required</span>
                      <span *ngIf="signinForm.controls['email'].errors?.['email']" class="danger">*Email is invalid</span>
                    </c-input-group>
                </c-input-group>
               
                <c-input-group class="mb-4">
                  <span cInputGroupText>
                    <svg cIcon name="cilLockLocked"></svg>
                  </span>
                  <input formControlName="password" class="mb-2" cFormControl placeholder="Password" type="password" />
                  <c-input-group *ngIf="submitting">
                    <span *ngIf="signinForm.controls['password'].errors?.['required']" class="danger error">*Password is
                      required</span>
                  </c-input-group>
                </c-input-group>
              
                <c-row>
                  <c-col xs="12" class="text-center">
                    <button cButton (click)="signIn()" class=" mx-auto d-block them-button" color="primary">
                      Login
                    </button>
                  </c-col>
                  <c-col class="text-center mt-3" xs="12">
                    <button cButton [routerLink]="['/forgot-password']" class="px-0 forgot-password-text" color="link">
                      Forgot Password?
                    </button>
                  </c-col>
                </c-row>
              </form>
            </c-card-body>
          </c-card>

        </c-card-group>
      </c-col>
    </c-row>
  </c-container>
</div>