import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/home/dashboard',
    iconComponent: { name: 'cilSpeedometer' },
  },
  {
    name: 'Users',
    url: '/home/users',
    iconComponent: { name: 'cilPeople' },
  },
  {
    name: 'Groups & Appointments',
    url: '/home/groups',
    iconComponent: { name: 'cilList' },
  },
  {
    name: 'Manage Subscriptions',
    url: '/home/subscriptions',
    iconComponent: { name: 'cilStar' },
  },
  {
    name: 'Manage Notifications',
    url: '/home/notifications',
    iconComponent: { name: 'cilBell' },
  },
];
