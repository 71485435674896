<div class="container conClass">
  <div class="center" *ngIf="emailVerified else noVerifiedEmail">
    <p>Email Verified Successfully!!
      .</p>
    <a
      href="https://kliquesocialde.page.link/?link={{baserUrl}}api/users/verifyaccount/{{tokenFromUrl}}&apn=com.kliquesocialde.app&isi=1640696912&ibi=com.kliquesocialde.app&type=verification">Open
      in app</a>
  </div>
  <ng-template #noVerifiedEmail>
    <div class="center">
      <p>Email Not Verified</p>
    </div>
  </ng-template>
</div>
<!-- https://kliquesocialde.page.link/?link=http://112.196.1.221:9058/api/users/verifyaccount/6a803337dd686b21258edd4b9d7006a0960b5373971f29d2d2f9fa788f3555a39c84a666bb0093e5b5636e7586f95c2e156bdd28deeff4cc0dae3c3542054a144563784fa2f984b8ec669184821731274b41f966c978eba62713ae286505e73fdf51f932c662a396eacc92e96dc132e4&apn=com.kliquesocialde.app&isi=1640696912&ibi=com.kliquesocialde.app&type=verification -->