<div class="min-vh-100 d-flex flex-row align-items-center" style="background-image: url(assets/images/preauthbg.png);">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
 <c-container>
   <c-row class="justify-content-center">
     <c-col lg="7" md="9" xl="6">
       <c-card class="mx-4">
         <c-card-body class="p-4">
           <form cForm [formGroup]="forgetForm" class="p-4">
             <h1 class="login-title text-center">Need help with your Password?</h1>
             <p class="text-medium-emphasis text-center mb-4">We will send new code to your recovery
              email to reset your password.</p>
             <c-input-group class="mb-3">
               <span cInputGroupText>@</span>
               <input autoComplete="email" formControlName="email" class="mb-2" cFormControl placeholder="Email" />
               <c-input-group *ngIf="submitting">
                <span *ngIf="forgetForm.controls['email'].errors?.['required']" class="danger error">*Email is required</span>
                <span *ngIf="forgetForm.controls['email'].errors?.['email']" class="danger error">*Email is invalid</span>
              </c-input-group>
             </c-input-group>
             
             <div class="d-grid">
               <button cButton color="primary" class="them-button" (click)="submitForm()">Send</button>
             </div>
             <div class="text-center pt-4">
              <p class="text-medium-emphasis forgot-password-text"><a [routerLink]="['/login']">Back to login</a></p>
             </div>
           </form>
         </c-card-body>
       </c-card>
     </c-col>
   </c-row>
 </c-container>
</div>
