<!--sidebar-->
<c-sidebar #sidebar="cSidebar" class="d-print-none sidebar sidebar-fixed" id="sidebar" visible>
  <c-sidebar-brand class="dashboardlogo" [brandFull]="{
      src: '/assets/images/logo-transparent.png',
      width: 200,
      height: 46,
      alt: 'Klique'
    }" [brandNarrow]="{
      src: '/assets/images/logo-transparent.png',
      width: 46,
      height: 46,
      alt: 'Klique'
    }" routerLink="./">
  </c-sidebar-brand>
  <perfect-scrollbar [config]="perfectScrollbarConfig">
    <!--  [perfectScrollbar]="perfectScrollbarConfig"-->
    <c-sidebar-nav [navItems]="navItems">
    </c-sidebar-nav>
  </perfect-scrollbar>
  <c-sidebar-toggler *ngIf="!sidebar.narrow" toggle="unfoldable" cSidebarToggle="sidebar"></c-sidebar-toggler>
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <!--app-header-->
  <app-default-header class="mb-4 d-print-none header header-sticky" position="sticky" sidebarId="sidebar">
  </app-default-header>
  <!--app-body-->
  <div class="body flex-grow-1 px-3">
    <c-container breakpoint="lg" class="h-auto">
      <router-outlet></router-outlet>
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer></app-default-footer>
</div>